
<template>
  <ion-page :cache-view="false">
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button @click="$router.push({name: 'MyProfile'})"></ion-back-button>
        </ion-buttons>
        <ion-title v-text="__('interface.privacy')"></ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <p class="ion-padding-horizontal">{{ __('interface.privacy.desc') }}</p>
      <div v-if="parseData.length > 0">
        <template v-for="item in parseData" :key="item.id">
          <ion-list-header v-text="item.name"></ion-list-header>
          <ion-list v-if="item.options.length > 0">
            <ion-item v-for="(option, key) in item.options" :key="key">
              <ion-label v-text="option.name"></ion-label>
              <ion-toggle :checked="option.enabled" :value="{club_id: item.id, enum: option.enum}"
                          @ionChange="changeOption"></ion-toggle>
            </ion-item>
          </ion-list>
        </template>
        <ion-button expand="full" class="ion-margin-vertical" @click="actionSave">{{ __('interface.save') }}</ion-button>
      </div>
    </ion-content>
  </ion-page>
</template>
<script>
import {IonPage, IonListHeader, loadingController, toastController} from "@ionic/vue";
import {API} from "@/utils/apiWrapper";

export default {
  name: "Privacy",
  components: {IonPage, IonListHeader},
  data() {
    return {
      loading: null,
      data: {
        privacy: [],
        clubs: []
      },
      parseData: [],
      optionsEnums: [
        {
          name: this.__('interface.height'),
          value: 'height'
        },
        {
          name: this.__('interface.weight'),
          value: 'weight'
        },
        {
          name: this.__('interface.age'),
          value: 'age'
        },
        {
          name: this.__('interface.resting-heart-rate'),
          value: 'resting-heart-rate'
        }
      ]
    }
  },
  methods: {
    prepareData() {
      this.data.clubs.forEach(club => {
        const options = []

        this.optionsEnums.forEach(optionsEnum => {
          const enabled = this.data.privacy.find(item => {
            return item.enum === optionsEnum.value && item.club_id === club.id && item.enabled
          }) !== undefined

          options.push({
            name: optionsEnum.name,
            enum: optionsEnum.value,
            enabled: enabled
          })
        })

        this.parseData.push({
          id: club.id,
          name: club.name,
          options: options
        })
      })
    },
    changeOption(e) {
      const privacyItem = this.data.privacy.find(item => {
        return item.club_id === e.detail.value.club_id && item.enum === e.detail.value.enum
      })

      if (privacyItem !== undefined) {
        privacyItem.enabled = e.detail.checked
      } else {
        this.data.privacy.push({
          club_id: e.detail.value.club_id,
          enum: e.detail.value.enum,
          enabled: e.detail.checked
        })
      }
    },
    async actionSave() {
      const loading = await loadingController.create({
        message: this.__('interface.loading'),
      });
      await loading.present();

      await API.put('TheSpottApi', '/me-privacy', {
        body: {
          privacy: this.data.privacy
        }
      }).then(async () => {
        await loading.dismiss();
        const toast = await toastController.create({
          message: this.__('message.saved-successfully'),
          duration: 2000
        })
        await toast.present();
        this.$tracking.event({
          eventName: 'update-privacy',
          category: 'privacy',
          event: this.form
        });
      }).catch(async () => {
        await loading.dismiss();
        const toast = await toastController.create({
          message: this.__('message.something-went-wrong'),
          duration: 2000
        })
        await toast.present();
      })
    }
  },
  async ionViewWillEnter() {
    this.parseData = []
    this.loading = await loadingController.create({
      message: this.__('interface.loading'),
    });
    await this.loading.present();
  },
  async ionViewDidEnter() {
    await API.get('TheSpottApi', '/me-privacy', {}).then(response => {
      this.data = response.data
      this.prepareData()
    }).finally(() => {
      this.loading.dismiss();
    })
  },
}
</script>